import React from 'react';
import { connectHits } from 'react-instantsearch-dom';

import ProductsCarousel from 'components/ProductsCarousel';

import './SearchResultsProducts.scss';

const AlgoliaSearchResultsProducts = ({
  hits,
  allProductDetails,
}: PageContent.SearchResultsProductsType) => {
  const allProductWithImg = hits?.map(({ sku }) => sku);
  const filteredProductsSku = allProductDetails.nodes.filter(({ sku }) =>
    allProductWithImg.includes(sku)
  );

  return (
    <div className="search-results-products">
      <ProductsCarousel products={filteredProductsSku} />
    </div>
  );
};

const SearchResultsProducts = connectHits(AlgoliaSearchResultsProducts);

export { AlgoliaSearchResultsProducts, SearchResultsProducts };
