import React, { useEffect, useState } from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import { Link } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';
import CustomButton from 'common/CustomButton';

import './SearchResultsItem.scss';

const AlgoliaSearchResultsItem = ({
  hits,
  translations,
  itemsPerPage,
}: PageContent.SearchResultsItemType) => {
  const hitsCount = hits.filter(({ isProduct }) => !isProduct).length;
  const [itemsToShow, setItemsToShow] = useState<PageContent.HitType[]>([]);
  const [next, setNext] = useState(itemsPerPage);
  useEffect(() => {
    setItemsToShow(hits.slice(0, itemsPerPage));
  }, [hits, itemsPerPage]);

  const handleShowMorePosts = () => {
    setNext(next + itemsPerPage);
    setItemsToShow(hits.slice(0, next + itemsPerPage));
  };

  const getNestedContent = (content) =>
    content.map((nested) =>
      Array.isArray(nested) ? (
        getNestedContent(nested)
      ) : (
        <DangerouslySetInnerHtml html={nested.value} />
      )
    );

  return (
    <>
      <span className="search-results-item__counter">{`${hitsCount} ${translations.resultsFoundLabel}`}</span>
      {itemsToShow.map(({ title, content, url, _snippetResult }) => {
        const contentToShow = _snippetResult ? _snippetResult.content : content;

        return (
          <div key={title} className="search-results-item">
            <Link aria-label={title} to={url} className="search-results-item__link">
              <h3 className="search-results-item__title">{title}</h3>
            </Link>
            {Array.isArray(contentToShow) ? (
              getNestedContent(contentToShow)
            ) : (
              <DangerouslySetInnerHtml
                html={(contentToShow as unknown as PageContent.HitType)?.value || contentToShow}
              />
            )}
          </div>
        );
      })}
      {hitsCount > itemsToShow.length ? (
        <div className="search-results-item__button">
          <CustomButton
            ariaLabel={translations.resultsFoundLabel}
            backgroundColor="red"
            buttonLabel={translations.showMoreButtonLabel}
            onClick={handleShowMorePosts}
          />
        </div>
      ) : null}
    </>
  );
};

const SearchResultsItem = connectInfiniteHits(AlgoliaSearchResultsItem);

export { AlgoliaSearchResultsItem, SearchResultsItem };
