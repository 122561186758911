import React, { useState } from 'react';
import { Configure, connectSearchBox, InstantSearch } from 'react-instantsearch-dom';
import { graphql } from 'gatsby';
import algoliasearch from 'algoliasearch/lite';
import { useScreenRecognition } from 'hooks/useScreenRecognition';

import SearchBar from 'common/SearchBar';

import { Results } from './Results';
import { SearchResultsItem } from './SearchResultsItem';
import { SearchResultsProducts } from './SearchResultsProducts';

import './SearchResults.scss';

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID as string,
  process.env.GATSBY_ALGOLIA_SEARCH_PUBLIC_KEY as string
);

const InstantSearchBar = connectSearchBox(SearchBar);

const SearchResults = ({
  searchBar: { searchBarText, placeholder, customButton },
  resultsFoundLabel,
  sortByLabel,
  relevanceSortingLabel,
  newestSortingLabel,
  noResultTitle,
  resultTitle,
  noResultText,
  searchResultLinks,
  showMoreButtonLabel,
  allProductDetails,
}: PageContent.SearchResultsType) => {
  const [algoliaIndex, setAlgoliaIndex] = useState(process.env.GATSBY_ALGOLIA_INDEX as string);
  const searchParams = new URLSearchParams(
    typeof window !== 'undefined' ? window.location.search : ''
  );
  const queryString = searchParams.get('q');
  const { isMobile } = useScreenRecognition();
  const hitsMobile = 6;
  const hitsDesktop = 8;

  const changeSortHandler = (value) => {
    setAlgoliaIndex(value);
  };

  return (
    <section className="search-results">
      <InstantSearch
        searchClient={searchClient}
        indexName={algoliaIndex}
        searchState={
          queryString
            ? {
                query: queryString,
              }
            : {}
        }
      >
        <Configure
          highlightPreTag={'<mark class="highlighted-text">'}
          highlightPostTag="</mark>"
          hitsPerPage={999}
        />
        <InstantSearchBar {...{ searchBarText, placeholder, customButton }} />
        <SearchResultsProducts {...{ allProductDetails }} />
        <div className="search-results__container" id="main-content">
          <Results
            {...{
              searchResultLinks,
              sortByLabel,
              relevanceSortingLabel,
              resultTitle,
              noResultTitle,
              newestSortingLabel,
            }}
            text={noResultText}
            changeSortHandler={changeSortHandler}
          >
            <SearchResultsItem
              itemsPerPage={isMobile ? hitsMobile : hitsDesktop}
              translations={{
                showMoreButtonLabel,
                resultsFoundLabel,
              }}
            />
          </Results>
        </div>
      </InstantSearch>
    </section>
  );
};

export const SearchResultsType = graphql`
  fragment SearchResultsType on SearchResultsType {
    searchBar {
      ...SearchBarType
    }
    resultsFoundLabel
    sortByLabel
    relevanceSortingLabel
    newestSortingLabel
    noResultTitle
    resultTitle
    noResultText
    searchResultLinks {
      ...CustomLinkType
    }
    showMoreButtonLabel
  }
`;

export default SearchResults;
