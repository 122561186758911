import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import SearchResults from 'components/SearchResults';

const SearchResultsPage = ({
  data: {
    searchResults: { urls, seo, searchResultsContent },
    allProductDetails,
  },
  pageContext: {
    breadcrumb: { crumbs },
    lang,
  },
}: SearchResultsTypes.SearchResultsProps) => {
  const { title, keywords, description } = seo;
  const crumbsSettings = {
    crumbs,
    isDisplayedCrumbs: true,
  };

  return (
    <Layout {...{ crumbsSettings }} headerTransparent className="search-results-page">
      <Seo {...{ lang, urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <SearchResults {...searchResultsContent} {...{ allProductDetails }} />
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String, $id: String) {
    searchResults(lang: { eq: $lang }, id: { eq: $id }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      searchResultsContent {
        ...SearchResultsType
      }
    }
    allProductDetails {
      nodes {
        title
        productImage {
          ...SalsifyImageType
        }
        sku
        url
      }
    }
  }
`;

export default SearchResultsPage;
